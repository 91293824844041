import React, { useState, useEffect } from 'react';
import './index.css';
import { Field, Label, Control, Input, Help, Button, Title } from 'bloomer';
import { pb_request } from '../../services/BackendApi';

export default (props) => {
  let [userPassword, setUserPassword] = useState('');
  let [userPasswordConfirm, setUserPasswordConfirm] = useState('');
  let [isPasswordsMatch, setIsPasswordMatch] = useState(null);
  let [submitError, setSubmitError] = useState(null);
  let [isUpdatePasswordSuccess, setIsUpdatePasswordSuccess] = useState(false);

  const onPasswordChange = (event) => {
    setUserPassword(event.target.value);
  }

  const onPasswordConfirmChange = (event) => {
    setUserPasswordConfirm(event.target.value);
  }

  const parseUrlSearch = (searchString) => {
    let pairStrings = searchString.split('?').filter((item) => {return item.length > 0});
    let pairs = pairStrings.map((item) => {return item.split('=')});
    let paramsObj = pairs.reduce((acc, cur) => {
      acc[cur[0]] = cur[1];
      return acc;
    }, {});
    return paramsObj;
  }

  const submitPasswords = () => {
    let { reset_password_token } = parseUrlSearch(props.location.search);
    let data = {
      resetPasswordToken: reset_password_token,
      password: userPassword
    }
    pb_request('PBUpdatePasswordRequest', data, false).then((res) => {
      console.log(res);
      setSubmitError(null)
      setIsUpdatePasswordSuccess(true);
    }, (err) => {
      console.log(err);
      setIsUpdatePasswordSuccess(false);
      setSubmitError(err);
    })
  }

  useEffect(() => {
    if (userPassword && userPasswordConfirm) {
      setIsPasswordMatch(userPassword === userPasswordConfirm)
    }
  }, [userPassword, userPasswordConfirm])

  return (
    <div className='main-container'>
      <div className='card-container'>
        <div className='title-container'>
          <Title isSize={3}>Change your password</Title>
        </div>
        <Field>
          <Label>Password</Label>
          <Control>
              <Input type="password" isColor={isPasswordsMatch !== null && !isPasswordsMatch ? 'danger' : ''} placeholder='password' value={userPassword} onChange={onPasswordChange} />
          </Control>
        </Field>

        <Field>
          <Label>Confirm Password</Label>
          <Control>
            <Input type='password' isColor={isPasswordsMatch !== null && !isPasswordsMatch ? 'danger' : ''} placeholder='password again ' value={userPasswordConfirm} onChange={onPasswordConfirmChange} />
          </Control>
          { isPasswordsMatch !== null && !isPasswordsMatch ? <Help isColor='danger'>passwords not match.</Help> : null }
        </Field>
        <Field>
          <Control>
            <Button isColor='primary' disabled={!(userPassword && userPasswordConfirm)} onClick={submitPasswords}>Submit</Button>
          </Control>
          {
            submitError && submitError.error ? <Help className='submit-message' isColor='danger'>{submitError.error_message}</Help> : null
          }
          {
            isUpdatePasswordSuccess ? <Help className='submit-message' isColor='success'>Update password success</Help> : null
          }
        </Field>

      </div>
    </div>
  )
}

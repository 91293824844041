import React from 'react';
import './index.css';
import Configs from '../../assets/datas/Configs';
import Navigator from '../../components/Navigator';

export default (props) => {
  return (
    <div>
      <Navigator appName={'RUN FOR FIT'} theme={'pure-light'} currentNav={'PRIVACY'} links={Configs.Links} />
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <div className="logo-line">
              Run For Fit
            </div>
          </div>

          <div className="container">
            <div className="customized-subtitle">
              <svg width="207px" height="21px" viewBox="0 0 207 21" version="1.1">
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontFamily="SFProText-Heavy, SF Pro Text" fontSize="24" fontWeight="600">
                      <g id="Desktop" transform="translate(-153.000000, -213.000000)" fill="#293541">
                          <text id="Terms-of-Service">
                              <tspan x="152" y="233">Terms of Service</tspan>
                          </text>
                      </g>
                  </g>
              </svg>
            </div>
            <p>
              Thank you for registering to use Run For Fit products. Please read this terms of service agreements (the “Terms”) carefully. By accessing or using this application in any way, you represent that you have read, understand and agree to be bound by the Terms.
            </p>
            <p>
              Please note that the Terms are subject to change by Run For Fit in its sole discretion at any time. When changes are made, Run For Fit will update the Terms. Any changes will be effective immediately for new users of Run For Fit and will be effective thirty (30) days after posting of such changes for existing users.
            </p>
            <p>
              Please regularly check the Terms to view the then-current agreement.
            </p>
            <p>
              <strong>Service Introduction</strong>
            </p>
            <p>
              Run For Fit provides you with a range of Internet products and services, including but not limited to running and walking products, online playlists and other related products. You may use, share, purchase products and services provided by Run For Fit. Run For Fit has the right to charge the resulting service or products fees.
            </p>
            <p>
              Run For Fit operators will do their best to provide unimpeded online services, but the platform’s network access services may be affected by a number of factors linked to the blocked, restricted or terminated. We will not be responsible for the loss or inconvenience caused.
            </p>
            <p>
              In order to enhance your experience and perfect the service content, Run For Fit has the right to develop anew service according to your use state and behavior of Run For Fit APP. You understand and agree that Run For Fit has the right not to give you special notice to update,, or to change or restrict part of the functions.
            </p>
            <p>
              <strong>User Accounts, Password, and Security</strong>
            </p>
            <p>
              In order to access certain features of Run For Fit, you may be required to become a registered user. In registering for the service, you agree to provide true, accurate, current and complete information about yourself. You represent that you are (1) of legal age to form a binding contract; and (2) not a person barred from using the Service under the laws of the United States.
            </p>
            <p>
              Run For Fit specifically reminds out protest your account and password and do not disclose the account number and password to others. You are responsible for keeping your account and password confidential and responsible for all activities the occur under your account and password.
            </p>
            <p>
              You can set a nickname on the account. The nickname shell not infringe or susceptibly infringement of the legitimate rights and interests of others. If the nickname you set is alleged to infringe upon the lawful rights and interests of others, Run For Fit has the right to terminate some or all services. You will bear any loss and adverse consequences arising from it.
            </p>
            <div className="p-like">
              <span>You agree to authorize the Keep operator to use the information for the following reasons:</span>
              <ul>
                <li>Perform software verification and upgrade service; </li>
                <li>Improve your security or provide customer support;</li>
                <li>When providing specific services at your special request, it is necessary to provide the information to Keep or third parties or other users associated with it; </li>
                <li>The use of various private privacy data for commercial purposes, including but not limited to providing value-added services, advertising, positioning advertising, marketing, joint registration, promotional or other activities to third parties; </li>
                <li>At the request of the judicial or the Government authorizations; </li>
                <li>To improve the user experience; </li>
                <li>Other circumstances which benefit the users and Keep, and do not violate any mandatory laws and regulations.</li>
              </ul>
            </div>
            <p>
              <strong>Responsibility for Content</strong>
            </p>
            <p>
              You acknowledge that all information, data, photos, videos, messages, emails, texts, music, sound, tags and/or other materials accessible through Run For Fit, whether publicly posted or privately transmitted, are the sole responsibility of the party from whom such content originated.
              This means that you, and not Run For Fit, are entirely responsible for all content that you upload, post, e-mail, transmit or otherwise make available.
            </p>
            <p>
              <strong>User Conduct </strong>
            </p>
            <p>
              As a condition of use, you agree not to use Run For Fit for any purpose that is prohibited by the Terms or by applicable law. You shall not (and shall not permit any third party) either (a) to take any action or (b) make available any content on or through the service that: (i) infringes or violates any patent, trademark, trade secret, copyright, contractual right, right of publicity or other right of any person or entity; (ii) violates any acceptable use or other information technology policy that may apply to your use of any computer system or network; (iii) is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, offensive, or profane; (iv) constitutes unauthorized or unsolicited advertising, junk or bulk e-mail; (v) involves commercial activities and/or sales without Run For Fit’s prior written consent, such as contests, sweepstakes, barter, advertising, or pyramid schemes; (vi) impersonates any person or entity, including any employee or representative of Run For Fit; or (vii) is inappropriate in any other manner that Run For Fit determines in its sole, reasonable discretion.
            </p>
            <p>
              <strong>Fees and Purchase Terms</strong>
            </p>
            <p>
              All fees set forth within and paid by you under the Terms is for the Run For Fit service. In no ways are these fees paid for for the license, or the use of Run For Fit application. You agree to pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. All fees for the Services are non-refundable. Run For Fit collects payments through a limited number of payment provider. Your payment provider agreement governs your use of the designated credit card or other payment method, and you must refer to that agreement and not the Terms to determine your rights and liabilities. Run For Fit reserves the right at any time to change its prices and billing methods.
            </p>
            <p>
              After your initial subscription period, and after any subsequent subscription period, your subscription will automatically commence on the first day following the end of such period and continue for an additional equivalent period, at Run For Fit’s then-current price for such subscription. If you want to cancel your subscription, you must sign into and go to the “Manage App Subscriptions” page in the Apple App Store at least 24 hours prior to the Renewal Commencement Date.
            </p>
            <p>
              <strong>Significant Others</strong>
            </p>
            <p>
              Run For Fit cannot guarantee that your content will be safe from outside attacks, hackers or other ways of accessing your content on the file systems.
            </p>
            <p>
              You understand and agree that in no event shall Run For Fit be liable for any indirect, incidental, special, exemplary or consequential damages arising out of or in connection with the application and the service.
            </p>
            <p>
              If Run For Fit becomes aware of any possible violations by you of the Terms, Run For Fit reserves the right to investigate such violations.
            </p>
          </div>
          <div className="container">
            <div className="customized-subtitle">
              <svg width="174px" height="25px" viewBox="0 0 174 25" version="1.1">
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontFamily="SFProText-Heavy, SF Pro Text" fontSize="24" fontWeight="600">
                      <g id="Desktop" transform="translate(-153.000000, -711.000000)" fill="#293541">
                          <text id="Privacy-Policy">
                              <tspan x="152" y="731">Privacy Policy</tspan>
                          </text>
                      </g>
                  </g>
              </svg>
            </div>
            <p>
              This Privacy Policy describes how Run For Fit handles personal information we collect about users at our mobile application.
            </p>
            <p>
              We may modify this Privacy Policy at any time. Any modifications to this Privacy Policy will be effective when posted (or as otherwise indicated at the time of posting). Continued use of Run For Fit after an update to this Privacy Policy will indicate your acceptance of the update.
            </p>
            <p>
              Please regularly check the Privacy Policy to view the then-current policy.
            </p>
            <p>
              <strong>Information You Give Us</strong>
            </p>
            <p>
              We collect your personal information when you process to use the app or create an account, such as your Apple Health data, email address and password. When you order paid versions of the service we collect information necessary to complete the transaction, including your name, payment information and billing information. You may share information with us when you request customer support or otherwise interact with us. If you decide to invite someone else to create an account on Run For Fit, we will collect your and the third party’s names and email addresses. You may choose to give us permission to access certain data on or components of your device, such as your photos, contacts, microphone and camera. You can always disable this access in your device’s settings.
            </p>
            <p>
              Sensitive Personal Information
            </p>
            <p>
              We will never ask you to send us or submit any information related to racial or ethnic origin; political opinions or union membership; religious or other beliefs; your health, biometric or genetic information; information about your sexual preference or sexual orientation; or information about your criminal background.
            </p>
            <p>
              <strong>Information We Collect automatically</strong>
            </p>
            <p>
              We use server logs, cookies and similar technologies to automatically collect information about your device and how you interact with Run For Fit. We may record your application version, browser type, mobile operating system, Internet Protocol address, etc.
            </p>
            <p>
              <strong>How We Use Your Personal Information</strong>
            </p>
            <div className="p-like">
              We use your information for the following purposes:
              <ol>
                <li>Provide and support the service.</li>
                <li>Better understand your interests and the service, make improvements to the service and to develop new features and services.</li>
                <li>Create anonymous data and share it with third parties to operate, understand and improve our service and for other lawful business purposes.</li>
                <li>Use your personal information as we believe appropriate to investigate or prevent violation of the law or our Terms of Service; to secure the service; to protect our, your or others’ rights, privacy, safety or property; and to protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.</li>
                <li>Use your personal information as we believe appropriate to (a) comply with applicable laws, lawful requests and legal process, and (b) to prosecute or defend legal claims.</li>
              </ol>
            </div>
            <p>
              <strong>How We Share Your Information </strong>
            </p>
            <div className="p-like">
              We share your information with certain entities for the following purposes:
              <ol>
                <li>Share your information with third party companies or individuals to provide services.</li>
                <li>Disclose information about you as we believe appropriate to government or law enforcement officials or private parties for compliance with law, legal claims, security, fraud prevention and safety.</li>
                <li>Sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business deal, such as a merger, consolidation, acquisition, reorganization, sale of assets or in the event of bankruptcy.</li>
              </ol>
            </div>
            <p>
              <strong>Security</strong>
            </p>
            <p>
              We use a variety of industry-standard technologies and procedures to help protect your personal information from unauthorized access, use, or disclosure. However, security risk is inherent in all information technology. Thus, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security.
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

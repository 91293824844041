import React from 'react';
import './index.css';
import Configs from '../../assets/datas/Configs';
import Navigator from '../../components/Navigator';
import HomeBanner from '../../components/HomeBanner';
import ContentSection from '../../components/ContentSection';
import BottomSection from '../../components/BottomSection';
import Footer from '../../components/Footer';

import AppShow from '../../assets/images/app-show02.png';
import RunOnBeach from '../../assets/images/run-on-beach.png';
import CurveLineBg from '../../assets/images/curve-line-bg.png';
import PhoneOutline from '../../assets/images/phone-outline.png';
import RedRunner from '../../assets/images/red-runner.png';
import DietImage from '../../assets/images/diet-image.png';
import MusicPlaylist3D from '../../assets/images/music-playlist-3d.png';
import MyWeight3D from '../../assets/images/my-weight-3d.png';
import MealPlan from '../../assets/images/meal-plan.png';
import CoolRunner from '../../assets/images/cool-runner.png';


export default (props) => {

  return (
    <div className='home-page-container'>
      <Navigator appName={'RUN FOR FIT'} theme={'light'} currentNav={'HOME'} links={Configs.Links} />
      <HomeBanner />
      <ContentSection
        sectionNumberText={'02'}
        sectionTitle={'WHAT IS RUN FOR FIT?'}
        sectionTextContent={'Run For Fit provides you with customized daily meal plans based on your fitness level. We provide plentiful healthy and detailed recipes to suit your taste. The combination of running and healthy diet can help boost your performance and achieve the optimal fitness result.  '}
        isDownLoadButtonRequired={true}
        customImageContainerClassname={'image-of-section2'}
        image={{
          src: AppShow,
          altText: 'screenshot of app',
          styles: {
            zIndex: 10,
            width: '500px',
            top: '-160px'
          }
        }}
      />
      <ContentSection
        sectionNumberText={'03'}
        sectionTitle={'SET A DAILY GOAL'}
        sectionTextContent={'It is hard to maintain a running habit without a schedule. Run For Fit creates an customized running schedule that will help you get started as well as follow through your workouts for the long run. '}
        isDownLoadButtonRequired={false}
        background={'light-pink'}
        image={{
          src: RunOnBeach,
          altText: 'a girl rush on the beach',
          styles: {

          }
        }}
      />
      <ContentSection
        sectionNumberText={'04'}
        sectionTitle={'TRACK YOUR PROGRESS'}
        sectionTextContent={'Run For Fit marks your everyday progress with a weight loss tracker that is scientifically proven to help you achieve better results. Run For Fit includes access to a verified food database that accurately tracks your calories. Nothing motivates you more than a chart that says you are losing weight everyday. '}
        isDownLoadButtonRequired={false}
        isReversed={true}
        customImageContainerStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        }}
        image={{
          src: PhoneOutline,
          altText: 'a beautiful girl with running pose',
          styles: {
            width: '60%',
            position: 'relative',
            zIndex: 5
          }
        }}
        oneMoreImage={{
          src: RedRunner,
          altText: 'outline image of iphone',
          styles: {
            position: 'absolute',
            left: '25%',
            width: '75%',
            zIndex: 0
          }
        }}
        background='track-line'
      />
      <ContentSection
        sectionNumberText={'05'}
        sectionTitle={'KEEP A DIET JOURNAL'}
        sectionTextContent={'Run For Fit provides you with customized daily meal plans based on your fitness level. The combination of running and healthy diet can help boost your performance and achieve the optimal fitness result. '}
        isDownLoadButtonRequired={false}
        background={'light-pink'}
        customImageContainerClassname={'custom-diet-image'}
        image={{
          src: DietImage,
          altText: 'a phone and dish on the table',
          styles: {

          }
        }}
      />
      <ContentSection
        sectionNumberText={'06'}
        sectionTitle={'CHOOSE A PERSONAL TRAINER'}
        sectionTextContent={'You can choose your own personal trainer, from sweet and encouraging to tough and military-style. Your personal trainer gives detailed audio instructions for every run. It also provides statistic updates and encouragements during your runs.'}
        isDownLoadButtonRequired={false}
        isReversed={true}
        isDeReversed={true}
        customLeftContaierStyle={{
          justifyContent: 'flex-start'
        }}
        customTextBlockClass='personal-trainer'
        customImageContainerStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%'
        }}
        image={{
          src: MyWeight3D,
          altText: 'screenshot of app about my weight records',
          styles: {
            maxWidth: '600px'
          }
        }}
        textBelongedImage={{
          src: MusicPlaylist3D,
          altText: 'screenshot of app about music playlist',
          styles: {},
          className: 'music-playlist'
        }}
      />
      <ContentSection
        sectionNumberText={'07'}
        sectionTitle={'PERSONALIZE YOUR MEAL PLAN'}
        sectionTextContent={'In addition to providing an exercise routine, Run For Fit also offers a customizable and well-balanced meal plan to help you achieve better fitness results.'}
        isDownLoadButtonRequired={false}
        image={{
          src: MealPlan,
          altText: 'healthy food dishes',
          styles: {
            width: '100%'
          }
        }}
      />
      <ContentSection
        sectionTextContent={'Run For Fit marks your everyday progress with a weight loss tracker that is scientifically proven to help you achieve better results. Run For Fit includes access to a verified food database that accurately tracks your calories. Nothing motivates you more than a chart that says you are losing weight everyday. '}
        isDownLoadButtonRequired={false}
        isReversed={true}
        isDeReversed={true}
        isDownLoadButtonRequired={true}
        customImageContainerStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
        image={{
          src: CoolRunner,
          altText: 'a beautiful girl ready to start running',
          styles: {
            width: '100%'
          }
        }}
      />
      <BottomSection />
      <Footer links={Configs.Links} />
    </div>
  )
}

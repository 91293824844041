const Links = [
  {
    href: '/',
    label: 'Home',
    navName: 'HOME'
  },
  {
    href: '/privacy',
    label: 'Privacy',
    navName: 'PRIVACY'
  }
];


export default {
  Links
}

import React from 'react';
import './index.css';

export default (props) => {
  return (
    <div className='page-container'>
      <div className='section'>
        <p className='message'>Page Not Found</p>
      </div>
    </div>
  )
}

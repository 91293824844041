import axios from 'axios';
import Protobuf from 'protobufjs';


const pb_request = (request_name, request_data, with_token) => {
  return new Promise((resolve, reject) => {
    Protobuf.load('/PBFUBodyBuilding.proto', function(err, root) {
      const { PBMessage, PBMessageType, PBErrorCode } = root;
      const Request = root[request_name];
      const path = Request.name.substring(2, Request.name.length - 7);

      let buffer = PBMessage.encode({
        id: 0,
        type: PBMessageType[Request.name.substring(2)],
        payload: Request.encode(request_data).finish()
      }).finish()

      const formData = new FormData();
      formData.append('data',new Blob([buffer], {type:"application/octet-stream"}));
      var headers = {
        'Content-Type': 'multipart/form-data'
      };
      if (with_token) {
        headers['Authorization'] = localStorage.getItem('access_token');
      }

      axios({
        method: 'POST',
        url: `https://api.runforfit.me/v1/${path}`,
        headers: headers,
        data: formData
      }).then((res) => {
        try {
          const pb_message = PBMessage.decode(new TextEncoder("utf-8").encode(res.data));

          if (pb_message.errorCode === PBErrorCode.ParamError) {
            reject({
              error: true,
              error_code: 'ParamError',
              error_message: pb_message.errorMessage
            })
            return
          }
          if (pb_message.errorCode !== PBErrorCode.Success) {
            reject({
              error: true,
              error_code: 'UnknownError',
              error_message: pb_message.errorMessage
            });
            return
          }

          const { payload }  = pb_message;
          try {
            const result = root[`PB${path}Reply`].decode(payload);
            resolve(result);
          } catch (e) {
            reject({
              error: true,
              error_code: 'DecodeError',
              error_message: 'Decode payload data failed',
              error_detail: e
            })
          }
        } catch (e) {
          reject({
            error: true,
            error_code: 'DecodeError',
            error_message: 'Decode response data failed',
            error_detail: e
          })
        }
      }).catch((err) => {
        console.log(err)
        reject({
          error: true,
          error_code: 'AxiosRequestError',
          error_message: 'Fetch data by Axios failed',
          error_detail: err
        })
      });
    });
  })
}

export { pb_request };

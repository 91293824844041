import React from 'react';
import './index.css';
import BottomPhone1 from '../../assets/images/bottom-phone-1.png';
import BottomPhone2 from '../../assets/images/bottom-phone-2.png';
import TryItButton from '../TryItButton';

export default (props) => {
  return (
    <div className='section bottom-section'>
      <div className='bottom-container'>
        <div className='left-container'>
          <div className='phones'>
            <img src={BottomPhone1} alt='app screenshot in phone model' className='phone1' />
            <img src={BottomPhone2} alt='app screenshot in phone model' className='phone2' />
          </div>
        </div>
        <div className='right-container'>
          <div className='actions'>
            <div className='bigger-text'>Didn't find what you want?</div>
            <div className='content-text'>Don't worry! Now download Run For Fit to explore more features!</div>
            <TryItButton theme='light' />
            <div className='policy-links'><span><a href='/privacy'>Privacy Policy</a> / <a href='/privacy'>Terms of Services</a></span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

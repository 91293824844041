import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import BannerGirl from '../../assets/images/banner_girl.png';
import TryItButton from '../TryItButton';

export default (props) => {
  return (
    <div className='section banner'>
      <div className='container-limitted'>
        <div className='line top'>
          <div className='top-left-corner'>RUN FOR FIT</div>
          <div className='top-right-corner'>
            <div>01</div>
            <div className='vertical-line'></div>
          </div>
        </div>
        <div className='line center'>
          <div className='circle-pair'>
            <div className='left-circle'></div>
            <div className='right-circle'></div>
          </div>
          <div className='main-app-name'>RUN FOR FIT</div>
          <div className='runner-image'>
            <img src={BannerGirl} alt='a girl is running' />
          </div>
        </div>
        <div className='line bottom'>
          <div className='text-for-phone'>Optimal Fitness with Ease</div>
          <div className='bottom-left-corner'>YOUR PERSONAL FITNESS TRAINER</div>
          <div className='bottom-right-corner'>
            <TryItButton theme='light' className='is-try-it-button-display' />
            <a href='https://itunes.apple.com/us/app/runforfit/id1381315131?mt=8' className='is-link-display'>
              Try It Now
              <FontAwesomeIcon
                  icon={faArrowRight}
                  color="#fff"
                  size="sm"
                />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import './index.css';

export default (props) => {
  return (
    <div className={`button-container ${props.className || ''}`}>
      <a href='https://itunes.apple.com/us/app/runforfit/id1381315131?mt=8' className={`the-button ${props.theme || 'hot'}`}>{props.buttonText || 'Try It Now'}</a>
    </div>
  )
}

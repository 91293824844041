import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import 'bulma/css/bulma.css';
import Home from './pages/Home';
import About from './pages/About';
import Privacy from './pages/Privacy';
import PasswordReset from './pages/PasswordReset';
import NotFound from './pages/NotFound';

export default (props) => {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path='/user/password/edit' component={PasswordReset} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/privacy.html" component={Privacy} />
          <Route component={NotFound}/>
        </Switch>
      </div>
    </Router>
  )
};

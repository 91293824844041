import React from 'react';
import './index.css';
import TryItButton from '../TryItButton';

export default (props) => {
  console.log(props.image);

  return (
    <div className={`section content ${props.isReversed ? 'reversed-contents' : ''} ${props.background ? `with-background-${props.background}` : ''}` }>
      <div className={`left`} style={props.customLeftContaierStyle || null}>
        <div className={`block ${props.customTextBlockClass || ''}`}>
          {props.textBelongedImage ? <img src={props.textBelongedImage.src} alt={props.textBelongedImage.altText}  className={props.textBelongedImage.className} style={props.textBelongedImage.styles} /> : null}
          <div className={`text${props.isReversed  && !props.isDeReversed ? ' reversed' : ''}`}>
            {props.sectionNumberText ? <div className='number-text'>{props.sectionNumberText}</div> : null}
            {props.sectionNumberText ? <div className={`vertical-line${props.isReversed  && !props.isDeReversed ? ' reversed' : ' not-reversed'}`}></div> : null}
            {props.sectionTitle ? <div className={`title${props.isReversed  && !props.isDeReversed ? ' reversed' : ''}`}>{props.sectionTitle}</div> : null}
            <div className={`text-content${props.isReversed && !props.isDeReversed ? ' reversed' : ''}`}>{props.sectionTextContent}</div>
            {
              props.isDownLoadButtonRequired ?
              <TryItButton /> : null
            }
          </div>
        </div>
      </div>
      <div className={`right${props.isReversed ? ' reversed' : ''} ${props.background ? `with-background-${props.background}` : ''}`}>
        <div className={`block image ${props.customImageContainerClassname}`} style={props.customImageContainerStyle}>
          <img src={props.image.src} alt={props.image.altText} style={props.image.styles} />
          {props.oneMoreImage ? <img src={props.oneMoreImage.src} alt={props.oneMoreImage.altText} style={props.oneMoreImage.styles} /> : null}
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import './index.css';

export default (props) => {
  return (
    <div className='footer-background-provider'>
      <div className='footer-container'>
        <div className='copyright'>
          <span>Copyright @ 2019 FansUp Inc. All rights reserved.</span>
        </div>
        <div className='links'>
          {
            props.links ?
            props.links.map((l, index) => {
              return (
                <a key={index} href={l.href}>{l.label}</a>
              )
            }) : null
          }
        </div>
      </div>
    </div>
  )
}
